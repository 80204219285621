.nav {
  position: fixed;
  display: flex;
  justify-content: space-between;
  margin: 40px 10% 0;
  width: 80%;
  z-index: 10;
}

.nav_icon {
  display: flex;
}

.nav_icon > img {
  width: 33px;
  height: 33px;
  object-fit: contain;
}

.nav_icon > p {
  color: #222222;
  font-size: 15px;
  line-height: 33px;
  padding-left: 20px;
  font-weight: bold;
}

.nav_text {
  width: 300px;
  justify-content: space-between;
  display: flex;
}

.nav_text > p {
  color: #222222;
  font-size: 15px;
  line-height: 33px;
  font-weight: bold;
}

@media (max-width: 500px) and (min-width: 0) {
  .nav_icon > p {
    display: none;
  }

  .nav_text {
    width: 150px;
  }

  .nav_text > p:nth-child(1) {
    display: none;
  }
}



.policy {
  width: 100vw;
  background-color: #F9FBFF;
  padding: 50px 0;
}

.policy > div {
  padding: 50px;
  width: 500px; 
  margin: 100px calc((100vw - 600px) / 2);
  background-color: white;
}

@media (max-width: 600px) and (min-width: 0) {
  .policy > div {
    padding: 50px 3vw;
    width: 88vw; 
    margin: 100px 3vw;
    background-color: white;
  }
}

.policy > div > h4 {
  text-align: center;
  color: #222222;
  line-height: 40px;
  margin-bottom: 30px;
}

.policy > div > p {
  text-align: left;
  color: #222222;
  line-height: 30px;
  margin-bottom: 30px;
  font-size: 14px;
}

.footer {
  width: 100% - 2px;
  background-color: #F5F5F5;
  border: 1px solid #EBEBEB;
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  background-color: #EBEBEB;
  width: 70%;
  padding: 20px 15%;
}

@media (max-width: 600px) and (min-width: 0px) {
  .footer_bottom {
    justify-content: space-evenly;
    margin: 0;
  }
}

.footer_bottom > div {
  display: flex;
  justify-content: space-between;
  width: 230px;
}

@media (max-width: 600px) and (min-width: 0px) {
  .footer_bottom > div {
    display: block;
  }
}

.footer_bottom > div > a > p {
  font-size: 13px;
  color: #222222;
  text-decoration: none;
}

.footer_bottom > p {
  font-size: 13px;
  color: #222222;
}




