.recruit {
  width: 600px;
  padding: 150px calc((100vw - 600px) / 2) 100px;
  background-color: #EAE3E2;
}

@media (max-width: 700px) and (min-width: 0px) {
  .recruit {
    width: 95%;
    padding: 50px 2.5% 100px;
    background-color: #EAE3E2;
  }
}

.footer {
  width: 100% - 2px;
  background-color: #F5F5F5;
  border: 1px solid #EBEBEB;
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  background-color: #EBEBEB;
  width: 70%;
  padding: 20px 15%;
}

@media (max-width: 600px) and (min-width: 0px) {
  .footer_bottom {
    justify-content: space-evenly;
    margin: 0;
  }
}

.footer_bottom > div {
  display: flex;
  justify-content: space-between;
  width: 230px;
}

@media (max-width: 600px) and (min-width: 0px) {
  .footer_bottom > div {
    display: block;
  }
}

.footer_bottom > div > a > p {
  font-size: 13px;
  color: #222222;
  text-decoration: none;
}

.footer_bottom > p {
  font-size: 13px;
  color: #222222;
}






/* 
.contents {
  position: absolute;
  z-index: 1;
  width: 290px;
  height: 695px;
  margin: 5px;
  overflow-y: scroll;
  border-radius: 30px;
}

.head_title {
  width: 100%;
  height: 40px;
}

.phone {
  position: absolute;
  width: 300px;
  height: 600px;
  background-image: url(./../../../../image/works/recruit/phone.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;
} */

/* @media (max-width: 1200px) and (min-width: 1000px) {
  .recruit {
    width: 1000px;
    display: flex;
    justify-content: space-between;
    padding: 50px calc((100vw - 1000px) / 2);
    min-height: 100vh;
  }
}

@media (max-width: 1000px) and (min-width: 700px) {
  .recruit {
    width: 700px;
    padding: 50px calc((100vw - 700px) / 2);
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .recruit {
    width: 90%;
    padding: 50px 5%;
  }
}

.left {
  width: 700px;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .left {
    width: 600px;
  }
}

@media (max-width: 1000px) and (min-width: 0px) {
  .left {
    width: 100%;
  }
} */

/* box 1 */


/* .store_top > div > p {
  font-size: 14px;
  padding: 10px 20px;
  margin-right: 10px;
  background-color: white;
  border: 2px solid #AD0200;
  border-radius: 3px;
} */


.left > button {
  border: none;
  width: 200px;
  height: 40px;
  line-height: 40px;
  background-color: #AD0200;
  border-radius: 3px;
  margin: 30px calc((100% - 200px) / 2) 0;
  color: white;
  font-weight: bold;
}



/* 経営理念 3選 */

.culture {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.culture > img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 5px;
}

.culture > div {
  width: calc(100% - 180px);
}

.culture > div > h4 {
  font-size: 16px;
  line-height: 40px;
  color: #222222;
  margin-top: 20px;
}

.culture > div > p {
  font-size: 14px;
  line-height: 30px;
  color: #444444;
}











.desc_title > p {
  font-size: 13px;
  color: #AD0200;
  font-weight: bold;
}

.desc_title {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}


.desc_bar {
  width: 330px;
  height: 40px;
  /* background-image: url(./../../../../../image/recruit/slider_site.png);
  background-size: contain;
  background-repeat: no-repeat; */
}

.desc_bar > img {
  width: 330px;
  height: 40px;
  object-fit: contain;
}

/* .desc_bar > input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  width: 330px;
  background: #EBEBEB;
  height: 5px;
  border-radius: 8px;
  margin-top: 0px;
  z-index: 5;
}

.desc_bar > input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #AD0200;
  border-radius: 50%;
}
 
.desc_bar > input[type="range"]::-moz-range-thumb {
  border: none;
  height: 15px;
  width: 15px;
  background-color: #AD0200;
  border-radius: 50%;
} */

.photos > h5 {
  font-size: 20px;
  color: #222222;
  margin: 0px 0 20px;
}

.photos > div {
  display: flex;
  flex-wrap: wrap;
}

.photos > div > img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 0 10px 10px 0;
}


/*  */


/*  */

.store_info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px 0;
}

.store_info_left {
  width: 45%;
}

@media (max-width: 700px) and (min-width: 0px) {
  .store_info_left {
    width: 280px;
    margin: 0 auto;
  }
}

.store_info_left > h4 {
  font-size: 14px;
  line-height: 30px;
  color: #222222;
}

.store_info_left > div > div {
  display: flex;
  justify-content: space-between;
  border-bottom: .5px solid #dfdfdf;
  padding: 10px 0 7px;
}

.store_info_left > div > div > h4 {
  width: 25%;
  line-height: 30px;
  color: #444444;
  font-size: 14px;
  font-weight: normal;
}

.store_info_left > div > div > h5 {
  width: 55%;
  line-height: 30px;
  font-size: 14px;
  color: #444444;
  font-weight: normal;
}

.store_info_left_icon {
  width: 16px;
  height: 16px;
  margin: 7px 10px 7px 0;
  color: #c63737;
}


/*  */
.text {
  width: calc(100% - 60px);
  padding: 30px 30px 15px;
  background-color: white;
  margin-bottom: 30px;
  box-shadow: 0px 0px 4px #dfdfdf;
  border-radius: 5px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .text {
    width: calc(100% - 40px);
    padding: 20px 20px 15px;
  }
}

.text > h3 {
  font-size: 18px;
  text-align: center;
  background-color: #F5DDDD;
  padding: 5px 0;
  border-radius: 3px;
  margin: 0 0 20px 0;
}

/* point */

.text_title {
  display: flex;
}

.text_title_icon {
  width: 30px;
  height: 30px;
  color: #AD0200;
}

.text_title {
  line-height: 30px;
  font-size: 14px;
  color: #222222;
}

.point {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.point_left {
  width: 60px;
  height: 60px;
  background-color: #AD0200;
  text-align: center;
  border-radius: 100px;
}

.point_left > p {
  font-size: 12px;
  line-height: 20px;
  color: white;
  font-weight: bold;
  margin-top: 10px;
}

.point_left > h4 {
  font-size: 16px;
  line-height: 20px;
  color: white;
}

.point_right {
  width: calc(100% - 100px);
}

.point_right > h4 {
  font-size: 16px;
  line-height: 30px;
  color: #222222;
}

.point_right > p {
  font-size: 14px;
  color: #555555;
}

/*  */

.image_appeal {
  width: 100%;
  margin-bottom: 30px;
}

.image_appeal > img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  margin: 0 0 20px 0;
}

.image_appeal > h4 {
  font-size: 18px;
  line-height: 40px;
  margin: 10px 0;
}

@media (max-width: 700px) and (min-width: 0px) {
  .image_appeal > h4 {
    font-size: 16px;
    line-height: 30px;
  }
}

.image_appeal > p {
  font-size: 14px;
  line-height: 25px;
  color: #555555;
  white-space: pre-wrap;
  word-wrap: break-word;
}

@media (max-width: 700px) and (min-width: 0px) {
  .image_appeal > p {
    font-size: 12px;
    line-height: 25px;
    color: #555555;
  }
}


/* 導入 */





.interview_pc {
  width: 400px;
  display: flex;
  justify-content: space-between;
}

/* .interview > div {
  width: 30%;
}

.interview > div > img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.interview > div > h4 {
  width: 100%;
  font-size: 16px;
  color: #222222;
  line-height: 30px;
  margin: 10px 0 0 0;
}

.interview > div > h5 {
  font-size: 12px;
  color: #A2A2A2;
  line-height: 20px;
}

.interview > div > h6 {
  font-size: 16px;
  color: #222222;
  margin: 20px 0 0 0;
}

.interview > div > p {
  font-size: 14px;
  color: #444444;
} */


