.footer {
  width: 100% - 2px;
  background-color: #F5F5F5;
  border: 1px solid #EBEBEB;
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  background-color: #EBEBEB;
  width: 70%;
  padding: 20px 15%;
}

@media (max-width: 600px) and (min-width: 0px) {
  .footer_bottom {
    justify-content: space-evenly;
    margin: 0;
  }
}

.footer_bottom > div {
  display: flex;
  justify-content: space-between;
  width: 230px;
}

@media (max-width: 600px) and (min-width: 0px) {
  .footer_bottom > div {
    display: block;
  }
}

.footer_bottom > div > a > p {
  font-size: 13px;
  color: #222222;
  text-decoration: none;
}

.footer_bottom > p {
  font-size: 13px;
  color: #222222;
}



